import * as React from "react";
import { graphql, Link, PageProps } from "gatsby";
import faqSettings from "settings/faq-page.json";
import {
  getAbsolutePageUrl,
  getPageUrl,
  getSafeExternalUrl,
  replaceObjDataInString,
} from "src/utils";
import { createPagesConfig } from "src/site-config";
import { Accordion } from "react-bootstrap";
import ReactMarkdown from "react-markdown";
import SEO from "components/seo";

/* #region Page Functions */
function insertTextTags(text: string, tags: {}) {
  return replaceObjDataInString(text, tags);
}
/* #endregion */

interface FaqPageSettings {
  questions:
    | []
    | {
        title: string;
        answer: string;
      }[];
}

interface FaqPageProps extends PageProps {
  data: {
    site: {
      siteMetadata: {
        siteName: string;
        siteUrl: string;
      };
    };
  };
}

const ContactPage = ({ data, location }: FaqPageProps) => {
  const pageSettings: FaqPageSettings = faqSettings;
  const replaceTextTags = {
    site: data.site.siteMetadata.siteName,
    siteUrl: `[${data.site.siteMetadata.siteUrl}](${getSafeExternalUrl(
      data.site.siteMetadata.siteUrl
    )})`,
  };

  return (
    <React.Fragment>
      <SEO
        title="FAQ (Perguntas Frequentes)"
        canonicalUrl={getAbsolutePageUrl(location.pathname)}
      />

      <h1 className="card-title mb-3">FAQ</h1>
      <h6 className="text-muted card-subtitle mb-2">Perguntas frequentes</h6>
      <p>
        Caso não encontre o que deseja, poste sua pergunta na página{" "}
        <Link to={getPageUrl(createPagesConfig.contactPageSlug)}>contato</Link>.
      </p>

      <div className="py-2">
        <Accordion>
          {pageSettings.questions?.map((q, i) => {
            return (
              <Accordion.Item key={i} eventKey={i.toString()}>
                <Accordion.Header>
                  <h5 className="m-0">
                    {insertTextTags(q.title, replaceTextTags)}
                  </h5>
                </Accordion.Header>
                <Accordion.Body>
                  <ReactMarkdown>
                    {insertTextTags(q.answer, replaceTextTags)}
                  </ReactMarkdown>
                </Accordion.Body>
              </Accordion.Item>
            );
          })}
        </Accordion>
      </div>
    </React.Fragment>
  );
};

export default ContactPage;

export const contactPageQuery = graphql`
  query {
    site {
      siteMetadata {
        siteName
        siteUrl
      }
    }
  }
`;
